import * as TYPE from "../constants";
import { REACT_APP_BASE_ENDPOINT } from "../../config/connection";
import RestClient from "../../config/RestClient";
import connection from "../../config/connection";

//Action Creator For Reducers

export const login_Success = (data) => ({
  type: TYPE.LOGIN_SUCCESS,
  data: data,
});

export const save_token = (data) => ({
  type: TYPE.SAVE_TOKEN,
  data: data,
});

export const save_credentials = (data) => ({
  type: TYPE.SAVE_CREDENTIALS,
  data: data,
});

export const login_user_info = (data) => ({
  type: TYPE.LOGIN_USER_INFO,
  data: data,
});

export const last_module_check = (data) => ({
  type: TYPE.LAST_MODULE_CHECK,
  data: data,
});

/****** action creator for login by Admin(Super User) ********/
export const superUserLogin = (token, cb) => {
  return (dispatch) => {
    dispatch(save_token(token));
  };
};

/****** action creator for login ********/
export const login = (params, cb) => {
  return (dispatch) => {
    RestClient.post(`${REACT_APP_BASE_ENDPOINT}/oauth/token/?email=${params.username}`, params)
      .then((result) => {
        dispatch(login_user_info(result));
        dispatch(save_token(result.access_token));
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

/******** action creator to log user out of the application **********/
export const logOut = (params, cb) => {
  return (dispatch) => {
    RestClient.post(`${REACT_APP_BASE_ENDPOINT}/oauth/logout/`, params)
      .then((result) => {
        let res = {
          status: true,
          msg: result
        }
        dispatch(save_token(""));
        dispatch(save_credentials({}));
        dispatch(login_user_info({}));
        dispatch(last_module_check({}));
        cb(res);
      })
      .catch((error) => {
      });
  };
};

/******** action creator to register in the application **********/
export const register = (params, cb) => {
  return (dispatch) => {
    RestClient.post(`${REACT_APP_BASE_ENDPOINT}/signup/`, params)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
      });
  };
};

/******** action creator to get client id and secret credentials for the application **********/
export const getCredentials = (cb) => {
  return (dispatch) => {
    RestClient.get(`${REACT_APP_BASE_ENDPOINT}/oauth/get-application/`)
      .then((result) => {
        let creds = {
          client_id: result.client_id,
          client_secret: result.client_secret
        }

        let res = {
          status: true,
          message: "Credentials received.",
          res: result
        };

        dispatch(save_credentials(creds));
        cb(res);
      })
      .catch((error) => {
        cb(error);
      });
  };
};

/******** action creator to register through social logins in the application **********/
export const registerSocialLogin = (params, cb) => {
  return (dispatch) => {
    RestClient.post(`${REACT_APP_BASE_ENDPOINT}/oauth/social-login/`, params)
      .then((result) => {
        dispatch(save_token(result.access_token));
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

export const registerSocialLoginTEMP = (params, cb) => { //new post api for sign using fb 
  return (dispatch) => {
    RestClient.post(`${REACT_APP_BASE_ENDPOINT}/oauth/social-login-temp/`, params)
      .then((result) => {
        console.log(result, 'POSTY____rESULT')
        dispatch(save_token(result.access_token));
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}


/******** action creator to view profile in the application **********/
export const profile = (params, cb) => {
  return (dispatch) => {
    RestClient.get(`${REACT_APP_BASE_ENDPOINT}/profile/`, {}, params.token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to update profile in the application **********/
export const updateProfile = (params, token, cb) => {
  return (dispatch) => {
    RestClient.postFormData(
      `${REACT_APP_BASE_ENDPOINT}/profile/`,
      params,
      token
    )
      .then((result) => {
        // dispatch(login_user_info(result.data.data));
        if(result?.data?.access_token) dispatch(save_token(result.data.access_token));
        cb(result)
      })
      .catch((error) => {
        console.log(error,"<- error");
        cb(error);
      });
  };
};


/**** get the video for the eterna for downloading */

export const getEternaVideos = (eternaId, token, cb) => {
  return (dispatch) => {
    RestClient.get(`${REACT_APP_BASE_ENDPOINT}/my-views/detail/download/`+eternaId, {},token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
};


/******** action creator to get countries in the application **********/
export const getCountries = (params, cb) => {
  return (dispatch) => {
    RestClient.get(`${REACT_APP_BASE_ENDPOINT}/oauth/countries/`, {}, params.token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to forgot password in the application **********/
export const forgotPassword = (params, cb) => {
  return (dispatch) => {
    RestClient.post(`${REACT_APP_BASE_ENDPOINT}/oauth/forgot-password/`, params)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to reset password in the application **********/
export const resetPassword = (params, id, cb) => {
  return (dispatch) => {
    RestClient.post(`${REACT_APP_BASE_ENDPOINT}/oauth/reset-password/${id}/`, params)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to change password in the application **********/
export const changePassword = (params, token, cb) => {
  return (dispatch) => {
    RestClient.post(`${REACT_APP_BASE_ENDPOINT}/oauth/change-password/`, params, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to get categories in the application **********/
export const getCategories = (params, cb) => {
  return (dispatch) => {
    RestClient.get(`${REACT_APP_BASE_ENDPOINT}/categories/`, {}, params.token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}


/******** action creator to get categories in the application **********/
export const getCategoriesOpen = ( cb) => {
  return (dispatch) => {
    RestClient.get(`${REACT_APP_BASE_ENDPOINT}/categories_without_auth/`, {})
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}


/******** action creator to get sub-categories in the application **********/
export const getSubCategories = (params, token, cb) => {
  return (dispatch) => {
    RestClient.get(`${REACT_APP_BASE_ENDPOINT}/categories/`, params.id, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to get sub-categories based on category in the application **********/
export const getSubCategoriesByCat = (id, token, cb) => {
  return (dispatch) => {
    RestClient.get(`${REACT_APP_BASE_ENDPOINT}/categories/${id}/`, {}, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to get sub-categories based on category in the application **********/
export const getSubCategoriesByCatOpen = (id, cb) => {
  return (dispatch) => {
    RestClient.get(`${REACT_APP_BASE_ENDPOINT}/categories_without_auth/${id}/`, {})
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}
/******** action creator to get question in the application **********/
export const getCreateQuestion = (url, token, cb) => {
  return (dispatch) => {
    RestClient.get(`${REACT_APP_BASE_ENDPOINT}/author-question/?${url}`, {}, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to save question in the application **********/
export const saveCreateQuestion = (params, token, cb) => {
  return (dispatch) => {
    if (localStorage.getItem('super')) {
      RestClient.postFormData(`${REACT_APP_BASE_ENDPOINT}/author-answer/?user_type=admin`, params, localStorage.getItem('super'))
        .then((result) => {
          cb(result);
        })
        .catch((error) => {
          cb(error);
        });
    } else {
      RestClient.postFormData(`${REACT_APP_BASE_ENDPOINT}/author-answer/`, params, token)
        .then((result) => {
          cb(result);
        })
        .catch((error) => {
          cb(error);
        });
    }
  };
}

/******** action creator to skip question in the application **********/
export const skipCreateQuestion = (params, token, cb) => {
  return (dispatch) => {
    RestClient.get(`${REACT_APP_BASE_ENDPOINT}/author-question/skipp/?`, params, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to check restore session for create module in the application **********/
export const getCreateRestoreSession = (cat, subcat, type, view_id, token, cb) => {
  return (dispatch) => {
    RestClient.get(`${REACT_APP_BASE_ENDPOINT}/author-question/restore-session/?category=${cat}&sub_category=${subcat}&type=${type}&view_id=${view_id}`, {}, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to get question by id in the application **********/
export const getQuestionById = (id, token, cb) => {
  return (dispatch) => {
    RestClient.get(`${REACT_APP_BASE_ENDPOINT}/author-question/${id}/`, {}, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to get text from audio in the application **********/
export const convertAudioToText = (params, token, cb) => {
  return (dispatch) => {
    RestClient.postFormData(`${REACT_APP_BASE_ENDPOINT}/chatgpt_intent/`,params, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

export const whisperAudioToText = (params, token, cb) => {
  return (dispatch) => {
    RestClient.postFormData(`${REACT_APP_BASE_ENDPOINT}/chatgpt_text/`,params, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

export const ChatGPtText = (params, token, cb) => {
  return (dispatch) => {
    RestClient.postFormData(`${REACT_APP_BASE_ENDPOINT}/chatgpt_intent_for_text/`,params, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to get text from audio in the application **********/
export const getChatGptIntents = (params, token, cb) => {
  return (dispatch) => {
    RestClient.post(`${REACT_APP_BASE_ENDPOINT}/chatgpt_intent_with_lex/`,params, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to get text from audio in the application **********/
export const getChatGptViewerQuestions = (params, token, cb) => {
  return (dispatch) => {
    RestClient.postFormData(`${REACT_APP_BASE_ENDPOINT}/viewer-question1/`,params, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}
/******** action creator to lex dummy query from audio in the application **********/

export const dummyAudioLex = (params, token, cb) => {
  return (dispatch) => {
    RestClient.postFormData(`${REACT_APP_BASE_ENDPOINT}/Dummy_audio_lex/`,params, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

export const dummyTextLex = (params, token, cb) => {
  return (dispatch) => {
    RestClient.postFormData(`${REACT_APP_BASE_ENDPOINT}/Dummy_text_lex/`,params, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}
/******** action creator to skip to new module in the application **********/
export const skipCurrentModule = (cat, subcat, viewId, token, cb) => {
  return (dispatch) => {
    RestClient.get(`${REACT_APP_BASE_ENDPOINT}/categories/get-next-module/?category=${cat}&subcat_id=${subcat}&view_id=${viewId}`, {}, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to get practice question in the application **********/
export const getPracticeQuestion = (url, token, cb) => {
  return (dispatch) => {
    RestClient.get(`${REACT_APP_BASE_ENDPOINT}/${url}`, {}, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to get practice question in the application **********/
export const postPracticeQuestion = (params, token, cb) => {
  return (dispatch) => {
    RestClient.postFormData(`${REACT_APP_BASE_ENDPOINT}/practice-answer/`, params, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to check restore session for practice module in the application **********/
export const getPracticeRestoreSession = (token, cb) => {
  return (dispatch) => {
    RestClient.get(`${REACT_APP_BASE_ENDPOINT}/practice-question/restore-session/`, {}, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
} 

/******** action creator to skip practice question in the application **********/
export const skipPracticeQuestion = (params, token, cb) => {
  return (dispatch) => {
    RestClient.get(`${REACT_APP_BASE_ENDPOINT}/practice-question/skip/?`, params, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to get my eternaviews in the application **********/
export const getMyEternaviews = (token, cb) => {
  return (dispatch) => {
    RestClient.get(`${REACT_APP_BASE_ENDPOINT}/myeternaview/`, {}, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to get all the practice questions answered in the application **********/
export const getPracticeAnswered = (token, cb) => {
  return (dispatch) => {
    RestClient.get(`${REACT_APP_BASE_ENDPOINT}/practice-question/listing/`, {}, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to get detail of particular question in the application **********/
export const getParticularQuestionDetail = (param, token, cb) => {
  return (dispatch) => {
    RestClient.get(`${REACT_APP_BASE_ENDPOINT}/${param}`, {}, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to get detail of particular question on interact screen in the application **********/
export const getInteractParticularQuestionDetail = (param, token, cb) => {
  return (dispatch) => {
    RestClient.get(`${REACT_APP_BASE_ENDPOINT}/${param}`, {}, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });           
  };
}

/******** action creator to scan all the questions module wise in the application **********/
export const scanQuestions = (param, paramurl, token, cb) => {
  return (dispatch) => {
    RestClient.get(`${REACT_APP_BASE_ENDPOINT}/author-question/module-wise/?mode=view${paramurl}`, param, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to scan all the questions module wise in the application **********/
export const scanQuestionsOpen = (param, paramurl, cb) => {
  return (dispatch) => {
    RestClient.get(`${REACT_APP_BASE_ENDPOINT}/author-question/module-wise/?mode=view${paramurl}`, param)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to scan all the questions module wise in congratulations screen in the application **********/
export const scanEditQuestions = (param, viewId, token, cb) => {
  return (dispatch) => {
    RestClient.get(`${REACT_APP_BASE_ENDPOINT}/author-question/module-wise/?mode=${param}&view_id=${viewId}`, {}, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}  

/******** action creator to scan all the questions module wise in the application **********/
export const completeCongratulationForm = (params, token, cb) => {
  return (dispatch) => {
    RestClient.post(`${REACT_APP_BASE_ENDPOINT}/author-question/complete/`, params, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to get answer for edit in the application **********/
export const getEditQuestion = (cat, subcat, question, token, cb) => {
  return (dispatch) => {
    RestClient.get(`${REACT_APP_BASE_ENDPOINT}/my-views/edit/?category_id=${cat}&module_id=${subcat}&question_id=${question}`, {}, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to update answer in the application **********/
export const updateAnswer = (cat, subcat, question, params, token, cb) => {
  return (dispatch) => {
    RestClient.postFormData(`${REACT_APP_BASE_ENDPOINT}/my-views/edit/?category_id=${cat}&module_id=${subcat}&question_id=${question}`, params, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to get my eternaviews in the application **********/
export const myEternaviewList = (token, cb) => {
  return (dispatch) => {
    RestClient.get(`${REACT_APP_BASE_ENDPOINT}/my-views/?limit=10`, {}, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to update eternaview's permission public/private in the application **********/
export const updateEternaviewPermission = (params, token, cb) => {
  return (dispatch) => {
    RestClient.post(`${REACT_APP_BASE_ENDPOINT}/my-views/update-permission/`, params, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to get public eternaviews in the application **********/
export const getPublicEternaviews = (cb) => {
  return (dispatch) => {
    if(localStorage.getItem('super')) {
        RestClient.get(`${REACT_APP_BASE_ENDPOINT}/public-views/?start=0&limit=50&user_type=admin`, {}, localStorage.getItem('super'))
        .then((result) => {
          cb(result);
        })
        .catch((error) => {
          cb(error);
        });

    } else {
        RestClient.get(`${REACT_APP_BASE_ENDPOINT}/public-views/?start=0&limit=50`, {}, '')
        .then((result) => {
          cb(result);
        })
        .catch((error) => {
          cb(error);
        });
    }

  };
}

/******** action creator to post question for interact in the application **********/
export const postInteractQuestion = (params, token, cb) => {
  return (dispatch) => {
    if(localStorage.getItem('super')) {
      RestClient.postFormData(`${REACT_APP_BASE_ENDPOINT}/viewer-question/?user_type=admin`, params, localStorage.getItem('super'))
        .then((result) => {
          cb(result);
        })
      .catch((error) => {
        cb(error);
      });
    } else {
      RestClient.postFormData(`${REACT_APP_BASE_ENDPOINT}/viewer-question/`, params, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
    }
  };
}

/******** action creator to get particular eternaview detail in the application **********/
export const getEternaviewDetail = (id, permission, token, cb) => {
  return (dispatch) => {
    RestClient.get(`${REACT_APP_BASE_ENDPOINT}/my-views/detail/?id=${id}&permission=${permission}`, {}, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to check if eternaview form is already filled in the application **********/
export const checkIfDone = (params, token, cb) => {
  return (dispatch) => {
    RestClient.get(`${REACT_APP_BASE_ENDPOINT}/my-views/verification/?`, params, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to get nodding video in the application **********/
export const getNoddingVideo = (cat, viewId, token, cb) => {
  return (dispatch) => {
    RestClient.get(`${REACT_APP_BASE_ENDPOINT}/categories/get-nodding-video/?category_id=${cat}&view_id=${viewId}`, {}, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to post question for interact practice in the application **********/
export const postPracticeInteractQuestion = (params, token, cb) => {
  return (dispatch) => {
    if(localStorage.getItem('super')) {
      RestClient.postFormData(`${REACT_APP_BASE_ENDPOINT}/practice-viewer/?user_type=admin`, params, localStorage.getItem('super'))
        .then((result) => {
          cb(result);
        })
        .catch((error) => {
          cb(error);
        });
    } else {
      RestClient.postFormData(`${REACT_APP_BASE_ENDPOINT}/practice-viewer/`, params, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
    }
  };
}

/******** action creator to like in the application **********/
export const likeEternaview = (id, token, cb) => {
  return (dispatch) => {
    RestClient.post(`${REACT_APP_BASE_ENDPOINT}/views-likes/?view_id=${id}`, {}, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to get nodding video in the application **********/
export const getPracticeNodding = (token, cb) => {
  return (dispatch) => {
    RestClient.get(`${REACT_APP_BASE_ENDPOINT}/practice-question/nodding-video/`, {}, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to send invite to interact eternaview in the application **********/
export const sendInviteToView = (param, token, cb) => {
  return (dispatch) => {
    RestClient.post(`${REACT_APP_BASE_ENDPOINT}/invites/`, param, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to send invite to interact eternaview in the application **********/
export const ResetCodeParamView = (param, token, cb) => {
  return (dispatch) => {
    RestClient.post(`${REACT_APP_BASE_ENDPOINT}/ResetCodeParamView/`, param, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to list all users who are invited to interact eternaview in the application **********/
export const listInviteUsers = (id, token, cb) => {
  return (dispatch) => {
    RestClient.get(`${REACT_APP_BASE_ENDPOINT}/invites/${id}/eternaview-shared-user/`, {}, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to list of eternaviews shared with me in the application **********/
export const inviteSharedWithMe = (token, cb) => {
  return (dispatch) => {
    RestClient.get(`${REACT_APP_BASE_ENDPOINT}/invites/share-with-me/`, {}, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to list of privacy setting of application **********/
export const privacySettings = (token, cb) => {
  return (dispatch) => {
    RestClient.get(`${REACT_APP_BASE_ENDPOINT}/privacy/setting/`, {}, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to revoke permission from list in the application **********/
export const revokePermissionView = (id, params, token, cb) => {
  return (dispatch) => {
    RestClient.post(`${REACT_APP_BASE_ENDPOINT}/invites/${id}/revoke-access/`, params, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to get all the viewers list in the application **********/
export const viewersList = (id, token, cb) => {
  return (dispatch) => {
    RestClient.get(`${REACT_APP_BASE_ENDPOINT}/viewer/listing/?id=${id}`, {}, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to get all answered questions in the application **********/
export const getAnsweredQuestions = (params, id, token, cb) => {
  return (dispatch) => {
    RestClient.get(`${REACT_APP_BASE_ENDPOINT}/author-question/answered-question/?author=${params}&view_id=${id}`, {}, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to check if eternaview started but not completed in the application **********/
export const checkEternaviewCreated = (token, cb) => {
  return (dispatch) => {
    RestClient.get(`${REACT_APP_BASE_ENDPOINT}/question/last/`, {}, token)
      .then((result) => {
        if (result.data.length > 0) {
          result.data.map((con, index) => {
            if (con.is_complete == false) {
              dispatch(last_module_check(con));
            }
          })
        }

        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to change last module bit popup in the application **********/
export const changeLastModal = (data, cb) => {
  return (dispatch) => {
    dispatch(last_module_check(data));
  };
}

/******** action creator to post privacy form in the application **********/
export const postPrivacyForm = (params, token, cb) => {
  return (dispatch) => {
    RestClient.post(`${REACT_APP_BASE_ENDPOINT}/privacy/setting/`, params, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to get practice eternaviews in the application **********/
export const getPracticeEternaviews = (token, cb) => {
  return (dispatch) => {
    RestClient.get(`${REACT_APP_BASE_ENDPOINT}/my-views/practice/`, {}, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to get plans dynamically in the application **********/
// export const getPlans = (token, cb) => {
//   return (dispatch) => {
//     RestClient.get(`${REACT_APP_BASE_ENDPOINT}/plans/`, {}, token)
//       .then((result) => {
//         cb(result);
//       })
//       .catch((error) => {
//         cb(error);
//       });
//   };
// }
export const getPlans = (token, cb) => {
  return (dispatch) => {
    const url = connection.environment == "production" ? "/plans_new/" : "/plans_new/"
    RestClient.get(`${REACT_APP_BASE_ENDPOINT}${url}`, {}, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to subscribe to plan in the application **********/
export const subscribeToPlans = (payload, token, cb) => {
  console.log(payload);
  return (dispatch) => {
    RestClient.post(`${REACT_APP_BASE_ENDPOINT}/plans/subscribe/`, payload, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to get subscription detail in the application **********/
export const getSubscriptionDetail = (token, cb) => {
  return (dispatch) => {
    RestClient.get(`${REACT_APP_BASE_ENDPOINT}/subscription/detail/`, {}, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to checkPlanTime in the application **********/
export const checkPlanTime = (params,token, cb) => {
  return (dispatch) => {
    RestClient.post(`${REACT_APP_BASE_ENDPOINT}/check_plan_time/`, params, token)
      .then((result) => {

        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

export const upgradePlan = (params,token, cb) => {
  return (dispatch) => {
    RestClient.post(`${REACT_APP_BASE_ENDPOINT}/CheckoutSession_schedule/`, params, token)
      .then((result) => {

        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}


/******** action creator to cancel subscription plan in the application **********/
export const cancelSubscription = (params, token, cb) => {
  return (dispatch) => {
    RestClient.post(`${REACT_APP_BASE_ENDPOINT}/plans/cancel/`, params, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to verify subscription whether user has taken any plan or not in the application **********/
export const verifyUserSubscription = (token, cb) => {
  return (dispatch) => {
    RestClient.get(`${REACT_APP_BASE_ENDPOINT}/plans/verify/`, {}, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to submit banner Visibilty eternaview in the application **********/
export const setNavigationBanner = (params, token, cb) => {
  return (dispatch) => {
    RestClient.post(`${REACT_APP_BASE_ENDPOINT}/banner_data_update/`, params, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to submit practice eternaview in the application **********/
export const submitPracticeEterna = (params, token, cb) => {
  return (dispatch) => {
    RestClient.post(`${REACT_APP_BASE_ENDPOINT}/practice-question/complete/`, params, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to create checkout session in the application **********/
export const createCheckoutSession = (params, token, cb) => {
  return (dispatch) => {
    RestClient.post(`${REACT_APP_BASE_ENDPOINT}/stripe/checkout/session/`, params, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}


/******** action creator to create checkout session in the application **********/
export const createScheduleCheckoutSession = (params, token, cb) => {
  return (dispatch) => {
    RestClient.post(`${REACT_APP_BASE_ENDPOINT}/CheckoutSession_schedule/`, params, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to create gift checkout session in the application **********/
export const createGiftCheckoutSession = (token,params, cb) => {
  return (dispatch) => {
    RestClient.post(`${REACT_APP_BASE_ENDPOINT}/gifts/`, params, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

export const getCheckoutURL = (token,params, cb) => {
  return (dispatch) => {
    RestClient.post(`${REACT_APP_BASE_ENDPOINT}/gift_checkout/`, params, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
} 

export const sendGiftEmailToUser = (params, cb) => {
  return (dispatch) => {
    RestClient.post(`${REACT_APP_BASE_ENDPOINT}/send-all-gift-emails/`, params)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
} 

export const redeemlogin = (params, cb) => {
  return (dispatch) => {
    RestClient.post(`${REACT_APP_BASE_ENDPOINT}/gift_authentication/`, params)
      .then((result) => {
        // dispatch(login_user_info(result));
        if(result.bearer_token)
        dispatch(save_token(result.bearer_token));
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
} 


export const subscribeUserTogift = (params,token, cb) => {
  return (dispatch) => {
    RestClient.post(`${REACT_APP_BASE_ENDPOINT}/subscription/detail_gift/`, params)
      .then((result) => {
        if(!token){
          dispatch(save_token(result.bearer_token));
        }
        // dispatch(login_user_info(result));
      
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
} 

export const subscribeUserToSchedule = (params,token, cb) => {
  return (dispatch) => {
    RestClient.post(`${REACT_APP_BASE_ENDPOINT}/subscription/detail_gift_schedule/`, params,  token)
      .then((result) => {
        if(!token){
          dispatch(save_token(result.bearer_token));
        }
        // dispatch(login_user_info(result));
      
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
} 

export const scheduledPlans = (token, cb) => {
  return (dispatch) => {
    const url = connection.environment == "production" ? "/scheduled_plans/" : "/scheduled_plans/"
    RestClient.get(`${REACT_APP_BASE_ENDPOINT}${url}`, {}, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}



/******** action creator to get all promocodes in the application **********/
export const getPromoCodes = (token, cb) => {
  return (dispatch) => {
    RestClient.get(`${REACT_APP_BASE_ENDPOINT}/stripe/promotion/codes/`, {}, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to request for reactivation of eternaview in the application **********/
export const requestReactivation = (id, token, cb) => {
  return (dispatch) => {
    RestClient.get(`${REACT_APP_BASE_ENDPOINT}/my-views/reactivation/?id=${id}`, {}, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to contact us in the application **********/
export const contactUs = (params, token, cb) => {
  return (dispatch) => {
    RestClient.post(`${REACT_APP_BASE_ENDPOINT}/contactus/`, params, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to renew subscription in the application **********/
export const renewSubscription = (params, token, cb) => {
  return (dispatch) => {
    RestClient.post(`${REACT_APP_BASE_ENDPOINT}/stripe/subscription/renewal/`, params, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to get number of queries without login in the application **********/
export const getQueryDetail = (id, token, cb) => {
  return (dispatch) => {
    RestClient.get(`${REACT_APP_BASE_ENDPOINT}/my-views/plan/info/?id=${id}`, {}, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to get about us data in the application **********/
export const getAboutUs = (token, cb) => {
  return (dispatch) => {
    RestClient.get(`${REACT_APP_BASE_ENDPOINT}/eterna/static/about_us/`, {}, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to get privacy policy data in the application **********/
export const getPrivacyPolicy = (token, cb) => {
  return (dispatch) => {
    RestClient.get(`${REACT_APP_BASE_ENDPOINT}/eterna/static/privacy_policy/`, {}, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to get terms and conditions data in the application **********/
export const getTermsConditions = (token, cb) => {
  return (dispatch) => {
    RestClient.get(`${REACT_APP_BASE_ENDPOINT}/eterna/static/terms_conditions/`, {}, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to get FAQ data in the application **********/
export const getFaq = (token, cb) => {
  return (dispatch) => {
    RestClient.get(`${REACT_APP_BASE_ENDPOINT}/eterna/static/frequently_asked_question/`, {}, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to get blog Listing in the application **********/
export const blogPosts = (token, cb) => {
  return (dispatch) => {
    RestClient.get(`${REACT_APP_BASE_ENDPOINT}/eterna/blogs/`, {}, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}
/*****Download file */

export const downloadFile = (url, token, cb) => {
  return (dispatch) => {
    RestClient.get(url,{}, {'Access-Control-Allow-Origin' : 'http://localhost:3000'})
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to get blog detail in the application **********/
export const blogDetail = (id, token, cb) => {
  return (dispatch) => {
    RestClient.get(`${REACT_APP_BASE_ENDPOINT}/eterna/blogs/${id}`, {}, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to get practice question detail in the application **********/
export const practiceQuestionDetail = (id, token, queryType, cb) => {
  return (dispatch) => {
    if(localStorage.getItem('super')) {
        RestClient.get(`${REACT_APP_BASE_ENDPOINT}/practice-question/${id}/?&user_type=admin&query_form=${queryType}`, {}, localStorage.getItem('super'))
        .then((result) => {
          cb(result);
        })
        .catch((error) => {
          cb(error);
        });
    } else {
        RestClient.get(`${REACT_APP_BASE_ENDPOINT}/practice-question/${id}/?query=yes&query_form=${queryType}`, {}, token)
        .then((result) => {
          cb(result);
        })
        .catch((error) => {
          cb(error);
        });
    }

  };
}

/******** action creator to edit title of eternaview in the application **********/
export const editTitle = (param, token, cb) => {
  return (dispatch) => {
    RestClient.post(`${REACT_APP_BASE_ENDPOINT}/my-views/title/`, param, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to submit email for newsletter on home screen in the application **********/
export const submitEmailNewsletter = (param, token, cb) => {
  return (dispatch) => {
    RestClient.post(`${REACT_APP_BASE_ENDPOINT}/news-letter/`, param, token)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}

/******** action creator to update profile in the application **********/
export const updatePaymentMethod = (params, token, cb) => {
  return (dispatch) => {
    RestClient.post(
      `${REACT_APP_BASE_ENDPOINT}/stripe/subscription/update/card/detail/`,
      params,
      token
    )
      .then((result) => {
        cb(result)
      })
      .catch((error) => {
        cb(error);
      });
  };
};

/******** action creator to Facebook Conversion API **********/
export const sharePixelTrack = (params,  cb) => {
  return (dispatch) => {
    RestClient.postFormData(`${REACT_APP_BASE_ENDPOINT}/post_event/`, params)
      .then((result) => {
        cb(result);
      })
      .catch((error) => {
        cb(error);
      });
  };
}